<template>
  <YLayoutBodyMenu>
    <el-card style="min-height: 45rem">
      <div style="margin-bottom: 1rem">
        <el-row :gutter="20">
          <el-col :span="3">
            <el-input v-model="searchForm.keyword" placeholder="购买用户昵称"></el-input>
          </el-col>
          <el-col :span="3">
            <YChooseAreaPro ref="areaPro" :mode="1" @change="chooseArea"></YChooseAreaPro>
          </el-col>
          <el-col :span="8">
            <el-date-picker
                style="width: 100%"
                v-model="searchForm.time"
                type="daterange"
                range-separator="至"
                start-placeholder="支付时间范围开始日期"
                end-placeholder="支付时间范围结束日期">
            </el-date-picker>
          </el-col>
          <el-col :span="2">
            <el-select v-model="searchForm.status">
              <el-option label="全部" :value="0"></el-option>
              <el-option label="待付款" :value="1"></el-option>
              <el-option label="已支付" :value="2"></el-option>
              <el-option label="已完成" :value="4"></el-option>
            </el-select>
          </el-col>
          <el-col :span="2">
            <el-select v-model="searchForm.pay_type">
              <el-option label="全部" :value="0"></el-option>
              <el-option label="小程序支付" :value="4"></el-option>
              <el-option label="公众号支付" :value="1"></el-option>
              <el-option label="激活码支付" :value="96"></el-option>
              <el-option label="支付宝支付" :value="5"></el-option>
              <el-option label="购买商品赠送" :value="97"></el-option>
              <el-option label="购买主理人赠送" :value="98"></el-option>
            </el-select>
          </el-col>
          <el-col :span="3">
            <el-button-group>
              <el-button @click="searchGo">搜索</el-button>
              <el-button @click="searchReset">重置</el-button>
            </el-button-group>
          </el-col>
        </el-row>
      </div>
      <el-table :data="list">
        <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
        <el-table-column label="下单用户" width="200" show-overflow-tooltip>
          <template v-slot="s">
            <div class="flex-def" style="width: 100%">
              <img :src="s.row.u_avatar | tomedia"
                   style="margin-right: 1rem;width: 3rem;height: 3rem;border-radius: 3rem">
              <div style="line-height: 1rem">
                <div>#{{ s.row.uid }}:{{ s.row.u_nickname }}</div>
                <!--                <div style="color: red">({{ userLevel2Tag(s.row.u_level) }})</div>-->
                <div>{{ s.row.u_phone }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="直接推荐人" width="200" show-overflow-tooltip>
          <template v-slot="s">
            <div v-if="s.row.from_uid > 0" class="flex-def" style="width: 100%">
              <img :src="s.row.from_u_avatar | tomedia"
                   style="margin-right: 1rem;;width: 3rem;height: 3rem;border-radius: 3rem">
              <div style="line-height: 1rem">
                <div>#{{ s.row.from_uid }}:{{ s.row.from_u_nickname }}</div>
                <!--                <div style="color: red">({{ userLevel2Tag(s.row.from_u_level) }})</div>-->
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="project" label="购买项目" :formatter="project2Tag"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="status" label="订单状态" :formatter="status2Tag" show-overflow-tooltip></el-table-column>
        <el-table-column prop="fee" label="支付金额" show-overflow-tooltip></el-table-column>
        <el-table-column prop="pay_type" label="支付方式" :formatter="PayTypeNum2Str"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="order_no" label="订单编号" show-overflow-tooltip></el-table-column>
        <el-table-column label="创建时间" show-overflow-tooltip>
          <template v-slot="s">{{ s.row.created_at | date }}</template>
        </el-table-column>
        <el-table-column label="支付时间" show-overflow-tooltip>
          <template v-slot="s">{{ s.row.pay_time | date }}</template>
        </el-table-column>
        <el-table-column fixed="right" width="120" label="操作" show-overflow-tooltip>
          <template v-slot="s">
            <OrderAward v-if="s.row.status > 1" :order_id="s.row.id"></OrderAward>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </YLayoutBodyMenu>
</template>

<script>

import {userLevel2Tag} from "@/common/mod/user";
import {project2Tag, status2Tag} from "@/common/mod/plugin/privilegeCard/order";
import {PayTypeNum2Str} from "@/common/mod/order";
import OrderAward from "@/components/card/OrderAward/OrderAward";
import YChooseAreaPro from "@/components/shop/YChooseAreaPro/YChooseAreaPro";
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";

export default {
  name: "order",
  components: {YLayoutBodyMenu, YChooseAreaPro, OrderAward},
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      searchForm: {
        pay_type: 0,
        keyword: "",

        p_code: "",
        c_code: "",
        d_code: "",
        s_code: "",

        status: 0,
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    chooseArea(e) {
      this.searchForm.p_code = e[0].value;
      this.searchForm.s_code = e[1].value;
      this.searchForm.d_code = e[2].value;
      this.searchForm.s_code = e[3].value;
    },
    searchReset() {
      this.searchForm = {
        keyword: "",

        p_code: "",
        c_code: "",
        d_code: "",
        s_code: "",

        status: 0,
      }
      this.$refs.areaPro.reset();
      this.searchGo();
    },
    searchGo() {
      this.page = 1;
      this.list = [];
      this.total = 0;
      this.load();
    },
    userLevel2Tag(e) {
      return userLevel2Tag(e);
    },
    project2Tag(e) {
      return project2Tag(e.project);
    },
    status2Tag(e) {
      return status2Tag(e.status);
    },
    PayTypeNum2Str(e) {
      return PayTypeNum2Str(e.pay_type);
    },
    pageChange(e) {
      this.page = e;
      this.load();
    },
    load() {
      this.$u.api.card.orderSearch({
        page: this.page,
        ...this.searchForm,
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
  },
}
</script>

<style scoped>

</style>