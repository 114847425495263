const GrantTypeZhiTui = 1
const GrantTypeJianDan = 2
const GrantTypePingJi = 3

const GrantCMOTypeYeji = 4

const GrantTypeNormalZhiTui = 5
const GrantTypeNormalJianTui = 7

const GrantTypeKnowledgeTeacher = 6

const GrantTypeAreaAgentArea = 8

const GrantTypeTeamManage = 9

const GrantTypeDealer = 10
const GrantTypeShareholder = 11

const GrantTypePrivilegeCardAgentInviteAgent = 12
const GrantTypePrivilegeCardInvite = 13
const GrantTypePrivilegeCardMerchantBoss = 14
const GrantTypePrivilegeCardAgentMerchantBoss = 15
const GrantTypePrivilegeCardAgentInviteMerchant = 16
const GrantTypePrivilegeCardAgentInviteAgentPingJi = 17
const GrantTypePrivilegeCardMerchantDepositReturn = 18

export function awardType2Tag(type) {
    let str = "";
    switch (parseInt(type)) {
        case GrantTypeZhiTui:
            str = "加盟区直推奖"
            break;
        case GrantTypeJianDan:
            str = "见单奖"
            break;
        case GrantTypePingJi:
            str = "平级奖"
            break;
        case GrantCMOTypeYeji:
            str = "总监业绩奖"
            break;
        case GrantTypeNormalZhiTui:
            str = "普通商品直推奖"
            break;
        case GrantTypeNormalJianTui:
            str = "普通商品间推奖"
            break;
        case GrantTypeKnowledgeTeacher:
            str = "知识付费讲师奖"
            break;
        case GrantTypeAreaAgentArea:
            str = "区域代理奖"
            break;
        case GrantTypeTeamManage:
            str = "团队奖"
            break;
        case GrantTypeDealer:
            str = "经销商奖"
            break;
        case GrantTypeShareholder:
            str = "股东奖"
            break;
        case GrantTypePrivilegeCardAgentInviteAgent:
            str = "免单卡代理直推代理奖"
            break;
        case GrantTypePrivilegeCardInvite:
            str = "免单卡推荐奖励"
            break;
        case GrantTypePrivilegeCardMerchantBoss:
            str = "免单卡商家老板专享权益奖励"
            break;
        case GrantTypePrivilegeCardAgentMerchantBoss:
            str = "免单卡代理商家老板专享权益奖励"
            break;
        case GrantTypePrivilegeCardAgentInviteMerchant:
            str = "免单卡代理直推商家权益奖励"
            break;
        case GrantTypePrivilegeCardAgentInviteAgentPingJi:
            str = "免单卡代理直推代理平级奖励"
            break;
        case GrantTypePrivilegeCardMerchantDepositReturn:
            str = "免单卡商家押金退回"
            break;
    }
    return str;
}